import axios from 'axios'
import { set } from 'vue'

export default {
  state: {
    data: []
  },
  mutations: {
    SET_MODELS(state, data) {
      set(state, 'data', data)
    }
  },
  actions: {
    async getModelsList({ commit }) {
      const res = await axios.get('/device/model/list', {
        params: { page: 1, limit: 50, deleted: false }
      })
      commit('SET_MODELS', res.data.content)
    },
    async onAddModel({ dispatch }, data) {
      const res = await axios.post('/device/model', data)
      dispatch('getModelsList')
      return res.status
    },
    async onEditModel({ dispatch }, { id, data }) {
      const res = await axios.put(`/device/model/${id}`, data)
      dispatch('getModelsList')
      return res.status
    }
  }
}
