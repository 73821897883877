<template>
  <v-navigation-drawer
    :value="open"
    :permanent="$vuetify.breakpoint.lgAndUp"
    app
    width="280px"
    class="py-4"
    @input="$emit('drawerChange', $event)"
  >
    <v-img
      class="mx-auto"
      width="185px"
      src="/assets/ekpa-logo-text-transparent.png"
    />

    <v-divider class="my-6" />

    <v-tabs vertical>
      <v-tab
        v-for="tab in tabs"
        :key="tab.to"
        :to="tab.to"
        class="justify-start ml-0"
      >
        <v-icon class="mr-4">
          {{ tab.icon }}
        </v-icon>

        <span class="text-body-1 font-weight-medium text-capitalize">
          {{ tab.text }}
        </span>
      </v-tab>
    </v-tabs>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs: [
        {
          to: '/requests',
          text: 'Αιτήσεις',
          icon: 'mdi-clipboard-text-outline'
        },
        {
          to: '/devices',
          text: 'Τηλεφωνικές συσκευές',
          icon: 'mdi-deskphone'
        },
        {
          to: '/number-pools',
          text: 'Αριθμοδοτικοί πόροι',
          icon: 'mdi-sim-outline'
        },
        {
          to: '/cucm-groups',
          text: 'Ευκολίες',
          icon: 'mdi-list-box-outline'
        },
        {
          to: '/warehouse',
          text: 'Αποθήκες',
          icon: 'mdi-warehouse'
        },
        {
          to: '/models',
          text: 'Μοντέλα',
          icon: 'mdi-phone-log'
        },
        {
          to: '/settings-templates',
          text: 'Πρότυπα ρυθμίσεων',
          icon: 'mdi-cog-outline'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
